<template>
    <div class="cover-password row-center">

        <div class="popup">

            <header>
                <img src="/img/logo-madrid.png" alt="">
            </header>

            <div class="contenedor-input">
                <label for="">Contraseña</label>
                <input type="text" v-model="pass">
            </div>

            <a @click="check" class="btn">Enviar</a>

        </div>

    </div>
</template>


<script>

import router from '@/router';

export default {
    name: 'password',
    mounted() {
        if (location.search.includes('ok')) {
            window.sessionStorage.setItem('pass', true);
            router.push({ name: 'Home' })
        }
    },
    data: () => ({
        pass: ''
    }),
    methods: {
        check() {
            if (this.pass == 'q5TXf]we}6Is|@AQM8*f') {
                window.sessionStorage.setItem('pass', true);
                router.push({ name: 'Home' })
            }
        }
    }
}
</script>


