<template>
    <div class="cover-password row-center">

        <div class="popup">

            <header>
                <img src="/img/logo-madrid.png" alt="">
            </header>

            <!-- <a href="https://cas.madrid.es/oauth2/authorize?response_type=code&client_id=2OdzOHkkKMxfDZZJlUMqyTpRnqoa&redirect_uri=https://madrid.dev.doubledot.es/login&scope=openid&state=dbaa9d36-a441-4b7a-bfbc-45d22a48c9ef"
                            class="btn">Entrar con office.com</a> -->

            <a href="https://cas.madrid.es/oauth2/authorize?response_type=code&client_id=2OdzOHkkKMxfDZZJlUMqyTpRnqoa&redirect_uri=https://iamportal.iformalia.es/login&scope=openid&state=dbaa9d36-a441-4b7a-bfbc-45d22a48c9ef"
                class="btn">Entrar con office.com</a>

        </div>

    </div>
</template>


<script>

import axios from 'axios';

import router from '@/router';

export default {
    name: 'password',
    mounted() {



        if (this.$route.query.code) {
            this.check();
        }
    },
    data: () => ({
        data: {
            ePassword: '',
            eUsuario: ''
        }
    }),
    methods: {
        requestToken() {

            const _0xe5a6 = ["\x50\x51\x68\x71\x37\x61\x6A\x54\x48\x67\x41\x4B\x34\x41\x62\x62\x6A\x32\x34\x5A\x69\x6F\x72\x61\x34\x36\x56\x4D\x72\x6D\x68\x53"];

            axios.post(`RequestToken`, { eToken: _0xe5a6[0] }).then(response => {
                sessionStorage.setItem('TOKEN_PUBLICO', JSON.stringify(response.data));
            }).catch(function (error) {
                console.log(error);
            });
        },
        check() {

            var data = {
                "eCode": this.$route.query.code
            }

            axios.post(`RequestLogin2`, data).then(response => {
                if (response.data.d.includes('access_token')) {
                    //window.sessionStorage.setItem('pass', true);
                    //router.push({ name: 'Home' })

                    //mrcambio: consulto si existe el usuario en la base de datos
                    window.sessionStorage.setItem('pass', true);
                    var obj = JSON.parse(response.data.d);
                    var token = obj.id_token
                    var sesion = JSON.parse(atob(token.split('.')[1]));

                    axios.post(`https://iamappi.iformalia.es/innoWS.asmx/RequestLoginWithoutPassword`, { eUsuario: sesion.email }).then(response => {
                        if (response.data.Usuario) {
                            router.push({ name: 'Home' });
                        } else {
                            console.log("no existe el usuario");
                        }
                    }).catch(function (error) {
                        console.log(error);
                    });

                    // axios.post(`http://localhost:50351/innoWS.asmx/RequestLoginWithoutPassword`, { eUsuario: sesion.email }).then(response => {
                    //     if (response.data.Usuario) {
                    //         axios.defaults.baseURL = 'http://localhost:50351/InnoWSPublico.asmx/'
                    //         router.push({ name: 'Home' });
                    //     } else {
                    //         console.log("no existe el usuario");
                    //     }
                    // }).catch(function (error) {
                    //     console.log(error);
                    // });
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
    }
}
</script>


<style lang="scss" scoped>
.btn {
    margin-top: 50px;
}
</style>